@tailwind base;
@tailwind components;
@tailwind utilities;

/*@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities'; */

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

amplify-authenticator {
	--container-height: inherit;
}

.hover-trigger .show-on-hover-trigger {
	display: none;
}

.hover-trigger:hover .show-on-hover-trigger {
	display: block;
}

.hover-trigger .hide-on-hover-trigger {
	display: block;
}

.hover-trigger:hover .hide-on-hover-trigger {
	display: none;
}

.hover-trigger .show-on-hover-trigger-flex {
	display: none;
}

.hover-trigger:hover .show-on-hover-trigger-flex {
	display: flex;
}

.hover-trigger .hide-on-hover-trigger-flex {
	display: flex;
}

.hover-trigger:hover .hide-on-hover-trigger-flex {
	display: none;
}

.filepond--credits {
	display: none;
}

.filepond--wrapper {
	grid-column: span 6 / span 6;
	--tw-border-opacity: 1;
	border-color: rgba(209, 213, 219, var(--tw-border-opacity));
	background-color: transparent !important;
}

@media (min-width: 30em) {
	.filepond--item {
		width: calc(50% - 0.5em);
	}
}

@media (min-width: 50em) {
	.filepond--item {
		width: calc(33.33% - 0.5em);
	}
}

.StripeElement {
	border-radius: 0.375rem;
	border: 1px solid #d1d5db;
	background: white;
	line-height: 1.25rem;
	font-size: 0.875rem;
	display: block;
	transition-duration: 150ms;

	padding-top: 0.5rem;
	padding-right: 0.75rem;
	padding-bottom: 0.5rem;
	padding-left: 0.75rem;
}

.StripeElement.IdealBankElement,
.StripeElement.FpxBankElement,
.StripeElement.PaymentRequestButton {
	padding: 0;
}

.StripeElement.PaymentRequestButton {
	height: 40px;
}

.ribbon {
	position: absolute;
	right: -5px;
	top: -5px;
	z-index: 1;
	overflow: hidden;
	width: 75px;
	height: 75px;
	text-align: right;
}
.ribbon span {
	font-size: 10px;
	color: #fff;
	text-transform: uppercase;
	text-align: center;
	font-weight: bold;
	line-height: 20px;
	transform: rotate(45deg);
	-webkit-transform: rotate(45deg); /* Needed for Safari */
	width: 100px;
	display: block;
	background: #b91c1c;
	background: linear-gradient(#ef4444 0%, #b91c1c 100%);
	box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
	position: absolute;
	top: 19px;
	right: -21px;
}
.ribbon span::before {
	content: '';
	position: absolute;
	left: 0px;
	top: 100%;
	z-index: -1;
	border-left: 3px solid #b91c1c;
	border-right: 3px solid transparent;
	border-bottom: 3px solid transparent;
	border-top: 3px solid #b91c1c;
}
.ribbon span::after {
	content: '';
	position: absolute;
	right: 0%;
	top: 100%;
	z-index: -1;
	border-right: 3px solid #b91c1c;
	border-left: 3px solid transparent;
	border-bottom: 3px solid transparent;
	border-top: 3px solid #b91c1c;
}

.sponsored {
	position: absolute;
	right: -5px;
	top: -5px;
	z-index: 1;
	overflow: hidden;
	width: 75px;
	height: 75px;
	text-align: right;
}

.sponsored span {
	font-size: 10px;
	color: #fff;
	text-transform: uppercase;
	text-align: center;
	font-weight: bold;
	line-height: 20px;
	transform: rotate(45deg);
	-webkit-transform: rotate(45deg); /* Needed for Safari */
	width: 100px;
	display: block;
	background: #e8c357;

	background: linear-gradient(#f4e3b1 0%, #e8c357);
	box-shadow: 0 3px 10px -5px rgb(7, 4, 4);
	position: absolute;
	top: 19px;
	right: -21px;
}

.sponsored span::before {
	content: '';
	position: absolute;
	left: 0px;
	top: 100%;
	z-index: -1;
	border-left: 3px solid #e8c357;
	border-right: 3px solid transparent;
	border-bottom: 3px solid transparent;
	border-top: 3px solid #e8c357;
}

.sponsored span::after {
	content: '';
	position: absolute;
	right: 0%;
	top: 100%;
	z-index: -1;
	border-right: 3px solid #e8c357;

	border-left: 3px solid transparent;
	border-bottom: 3px solid transparent;
	border-top: 3px solid #e8c357;
}

.pinned {
	position: absolute;
	right: -5px;
	top: -5px;
	z-index: 1;
	overflow: hidden;
	width: 75px;
	height: 75px;
	text-align: right;
}

.pinned span {
	font-size: 10px;
	color: #fff;
	text-transform: uppercase;
	text-align: center;
	font-weight: bold;
	line-height: 20px;
	transform: rotate(45deg);
	-webkit-transform: rotate(45deg); /* Needed for Safari */
	width: 100px;
	display: block;
	background: #3b82f6;

	background: linear-gradient(#60a5fa 0%, #3b82f6);
	box-shadow: 0 3px 10px -5px rgb(7, 4, 4);
	position: absolute;
	top: 19px;
	right: -21px;
}

.pinned span::before {
	content: '';
	position: absolute;
	left: 0px;
	top: 100%;
	z-index: -1;
	border-left: 3px solid #3b82f6;
	border-right: 3px solid transparent;
	border-bottom: 3px solid transparent;
	border-top: 3px solid #3b82f6;
}

.pinned span::after {
	content: '';
	position: absolute;
	right: 0%;
	top: 100%;
	z-index: -1;
	border-right: 3px solid #3b82f6;
	border-left: 3px solid transparent;
	border-bottom: 3px solid transparent;
	border-top: 3px solid #3b82f6;
}

.tooltip {
	visibility: hidden;
	position: absolute;
	z-index: 1;
	width: fit-content;
	color: white;
	font-size: 12px;
	background-color: #192733;
	border-radius: 10px;
	padding: 10px 15px 10px 15px;
}

.hastooltip:hover .tooltip {
	visibility: visible;
}

.hastooltip {
	position: relative;
	font-family: Arial;
	text-align: center;
}

input[type='text']:focus {
	box-shadow: none;
}

input[type='text']:focus:hover {
	border-color: #a0c8f7 !important;
}

.ad-image {
	border: 1px solid #000;
	padding: 10px;
}

.ad {
	width: 380px;
	height: 180px;
}

.ad-space {
	flex-shrink: 1;
	width: 100%;
	height: 380px;
	background-color: rgb(249 250 251);
}

.no-scrollbar::-webkit-scrollbar {
	display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
}
